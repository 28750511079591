import React, { } from 'react';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetFoodCardUserMemberListParameters, GetFoodCardUserMemberRes, DeleteFoodCardUserMemberParameters, GetFoodCardUserMemberFamilyRes, GetFoodCardUserMemberFamilyOldRes, GetFoodCardRes, GetFoodCardUserMemberFamilyOldListParameters, GetFoodCardListParameters, GetKioskVendingRes, GetKioskVendingListParameters, PostFoodCardUserMemberDownloadXlsxParameters, GetFoodCardUserMemberFamilyStatusRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import FoodCardUserMemberEditAndAdd, { FoodCardUserMemberEditAndAddPropsType } from './FoodCardUserMemberEditAndAdd';
import { PlusOutlined, DownloadOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';
import moment from 'moment';
import FoodCardDetailsModal from '../foodCard/FoodCardDetailsModal';

const { confirm } = Modal;

type EditAndAddModalType = FoodCardUserMemberEditAndAddPropsType

interface PageState {
  isSpinLoading: boolean
  spinDownloadXlsx?: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
  foodCardDetailsModal: {
    show?: boolean
    key?: any
    foodCardCode: string
  }
  kioskVendings: GetKioskVendingRes[]

}
interface Props {
  foodCardUserMemberFamilyId?: string
  onAddSuccessAction?(): void
  onEditSuccessAction?(): void
  onAddFoodCardSuccessAction?(): void
  onDeleteFoodCardSuccessAction?(): void

}


interface Page {


}


type PageProps = Props & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


type TableOldData = (GetFoodCardUserMemberFamilyOldRes & { foodCard?: GetFoodCardRes })


type TableData = GetFoodCardUserMemberRes & {
  olds: TableOldData[]
}

class FoodCardUserMemberList extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'add',
        show: false,
        key: new Date().toString(),
        foodCardUserMemberFamilyId: '',
      },
      foodCardDetailsModal: {
        foodCardCode: '',
      },
      kioskVendings: [],
    }
  }
  tableRef?: JJ_Table<TableData> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      const kioskVendingList = await this._getKioskVendingList({ count: 1000 })

      this.setState({
        isSpinLoading: false,
        kioskVendings: kioskVendingList.data,

      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _deleteFoodCardUserMember = async (params: DeleteFoodCardUserMemberParameters) => {
    const res = await api.DeleteFoodCardUserMember(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardUserMemberFamilyOldList = async (params: GetFoodCardUserMemberFamilyOldListParameters) => {
    const res = await api.GetFoodCardUserMemberFamilyOldList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getFoodCardList = async (params: GetFoodCardListParameters) => {
    const res = await api.GetFoodCardList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postFoodCardUserMemberDownloadXlsx = async () => {
    const res = await api.PostFoodCardUserMemberDownloadXlsx()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _oldSubTable = (data: TableData) => {
    return (
      <JJ_Table<TableOldData>
        title={`舊卡`}
        key={`${data.id}${Date.now()}`}
        style={{ padding: 20, backgroundColor: 'rgba(0,0,0,0.1)' }}
        isSearchText={false}
        columns={[

          {
            title: `食品卡號碼`,
            dataIndex: 'foodCardCode',
            key: 'foodCardCode',
            width: 200,
          },
          {
            title: '狀態',
            dataIndex: 'foodCardStatus',
            key: 'foodCardStatus',
            width: 150,
            render: (value, record) => {
              if (!record.foodCard) {
                return
              }

              if (record.foodCard.isEnable !== undefined && !record.foodCard.isEnable) {
                return <Tag color="red">已停用</Tag>
              }

              if (record.foodCard.beginAt && moment().isBefore(moment(record.foodCard.beginAt), 'day')) {
                //如果現在時間小於開始時間  未開始
                // 20 < 21
                return <Tag color="red">未開始</Tag>
              }

              if (record.foodCard.endAt && moment().isSameOrAfter(moment(record.foodCard.endAt), 'day')) {
                //如果現在時間小於開始時間  未開始
                //20 >= 19
                return <Tag color="red">已結束</Tag>
              }

              return <Tag color="blue">生效中</Tag>

            }
          },

          {
            title: '啟用時間',
            dataIndex: 'beginAt',
            key: 'beginAt',
            render: (value, record) => {

              if (record.foodCard) {
                return record.foodCard.beginAt
                  ? (<JJ_TableItemMoment
                    date={record.foodCard.beginAt}
                  />)
                  : '--:--'
              }


            }

          },
          {
            title: '停用時間',
            dataIndex: 'endAt',
            key: 'endAt',
            render: (value, record) => {

              if (record.foodCard) {
                return record.foodCard.endAt
                  ? (<JJ_TableItemMoment
                    date={record.foodCard.endAt}
                  />)
                  : '--:--'
              }
            }
          },
        ]}
        onDataSource={async (body) => {
          return {
            data: data.olds,
            totalCount: data.olds.length
          }
        }}
        tableProps={{ pagination: false }}

      />
    )
  }



  _getStatusView = (status: GetFoodCardUserMemberFamilyStatusRes | string) => {

    switch (status) {
      case 'ENDED': {
        return <Tag color="blue">已完结</Tag>
      }
      case 'IN_SERVICE': {
        return <Tag color="success">服務中</Tag>
      }
      case 'NOT_SET': {
        return <Tag color="pink">未設置</Tag>
      }
      case 'PAUSED': {
        return <Tag color='warning'>暫停中</Tag>
      }
      case 'TERMINATED': {
        return <Tag color="red">已終止</Tag>
      }
      case 'WAITING': {
        return <Tag >未開始</Tag>
      }
      case 'INVISIBLE': {
        return <Tag color='pink'>隱形</Tag>
      }
      default: {
        return <div />
      }
    }
  }

  render() {
    return (
      <div id="FoodCardUserMemberList"
      >


        {this.state.foodCardDetailsModal.key && (
          <FoodCardDetailsModal
            key={this.state.foodCardDetailsModal.key}
            title={'食品卡詳情頁'}
            visible={!!this.state.foodCardDetailsModal.show}
            onCancel={() => this.setState({ foodCardDetailsModal: { ...this.state.foodCardDetailsModal, show: false } })}
            foodCardCode={this.state.foodCardDetailsModal.foodCardCode}
          />
        )}

        <Modal
          maskClosable={false}
          title={this.state.editAndAddModal.type === 'edit'
            ? '編輯用戶家庭成員'
            : '新建用戶家庭成員'
          }
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <FoodCardUserMemberEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'add': {
                  message.success(`添加成功`)
                  this.props.onAddSuccessAction && this.props.onAddSuccessAction()
                }
                  break
                case 'edit': {
                  message.success(`編輯成功`)
                  this.props.onEditSuccessAction && this.props.onEditSuccessAction()
                }
                  break

              }

              this.tableRef && await this.tableRef.refreshData()


            }}
          />
        </Modal>


        <Space>

          <Button
            type='primary'
            loading={this.state.spinDownloadXlsx}
            style={{ marginBottom: 5 }}
            onClick={async () => {
              try {
                this.setState({
                  spinDownloadXlsx: true
                })
                const data = await this._postFoodCardUserMemberDownloadXlsx()
                window.open(data.url)
                this.setState({
                  spinDownloadXlsx: false
                })
              } catch (error) {
                this.setState({
                  spinDownloadXlsx: false
                })
                console.log(error)
              }

            }}
            icon={<DownloadOutlined />}
          >
            {'導出家庭成員XLSX'}
          </Button>

        </Space>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<TableData, {
              desc: 'ascend' | 'descend'
              status?: GetFoodCardUserMemberListParameters['status']
              gender?: GetFoodCardUserMemberListParameters['gender']
              kioskVendingIds: GetFoodCardUserMemberListParameters['kioskVendingIds']
              foodStartRange?: [moment.Moment, moment.Moment] | null
              foodStopRange?: [moment.Moment, moment.Moment] | null
              dateOfBirth: [moment.Moment, moment.Moment] | null
              familyStatus?: GetFoodCardUserMemberListParameters['familyStatus']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  span: 3,
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },

                {
                  type: 'rangePicker',
                  key: 'appliedAt',
                  span: 5,
                  props: {
                    placeholder: ['開始申請日期', '結束申請日期']
                  }
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  mode: 'multiple',
                  key: 'kioskVendingIds',
                  placeholder: '請選擇任意取餐機',
                  allowClear: true,
                  span: 5,
                  filterOption: (input, option) => {
                    if (option && option.key) {
                      return (this.state.kioskVendings[Number(option.key)].name as string).toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        (this.state.kioskVendings[Number(option.key)].code as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    return false
                  },
                  options: this.state.kioskVendings.map(kioskVending => {
                    return {
                      value: kioskVending.id,
                      name: (
                        <Space align='center'
                          style={{

                          }}
                        >
                          <Tag color={'orange'}>{kioskVending.name}</Tag>
                          <Tag color={'blue'}>{kioskVending.code}</Tag>
                        </Space>
                      ),
                      disabled: false,
                    }
                  }),
                },

                // {
                //   type: 'select',
                //   defaultValue: undefined,
                //   key: 'status',
                //   placeholder: '請選擇任意狀態',
                //   allowClear: true,
                //   span: 4,
                //   options: [
                //     { key: 'NORMAL', title: '正常', color: 'green' },
                //     { key: 'EXEMPTED', title: '豁免', color: 'blue' },
                //     { key: 'REFERRAL', title: '轉介中', color: 'purple' },
                //     { key: 'QUIT_SERVICE_DURING_WAITING', title: '輪候時已退出', color: 'red' },
                //   ].map(data => {
                //     return {
                //       value: data.key,
                //       name: (
                //         <Space align='center' >
                //           <Tag color={data.color}>{data.title}</Tag>
                //         </Space>
                //       ),
                //       disabled: false,
                //     }
                //   }),
                // },
                {
                  type: 'select',
                  defaultValue: undefined,
                  key: 'gender',
                  placeholder: '請選擇任意性別',
                  allowClear: true,
                  span: 3,
                  options: [
                    { key: 'F', color: 'pink', title: '女' },
                    { key: 'M', color: 'blue', title: '男' },
                  ].map(data => {
                    return {
                      value: data.key,
                      name: (
                        <Space align='center' >
                          <Tag color={data.color}>{data.title}</Tag>
                        </Space>
                      ),
                      disabled: false,
                    }
                  }),
                },

                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意家庭狀態',
                  key: 'familyStatus',
                  allowClear: true,
                  span: 3,
                  options: [
                    'WAITING',
                    'IN_SERVICE',
                    'ENDED',
                    'TERMINATED',
                    'PAUSED',
                    'NOT_SET',
                    'INVISIBLE',
                  ].map(item => ({
                    value: item,
                    name: this._getStatusView(item),
                    disabled: false,
                  }))
                },
                {
                  type: 'rangePicker',
                  key: 'foodStartRange',
                  span: 7,
                  props: {
                    placeholder: ['食品卡啟動開始時間', '食品卡啟動結束時間'],
                  }
                },
                {
                  type: 'rangePicker',
                  key: 'foodStopRange',
                  span: 7,
                  props: {
                    placeholder: ['食品卡停用開始時間', '食品卡停用結束時間'],
                  }
                },

                {
                  type: 'rangePicker',
                  key: 'dateOfBirth',
                  span: 7,
                  props: {
                    placeholder: ['出生日期開始時間', '出生日期結束時間'],
                    allowClear: true,
                    picker: 'year',

                  }
                },
              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: !!!record.foodCardCode,
                            key: 'showFoodCardDetails',
                            name: '顯示食品卡詳情',
                            onAction: () => {
                              const foodCardCode = record.foodCardCode
                              if (foodCardCode) {
                                this.setState(state => ({
                                  foodCardDetailsModal: { ...state.foodCardDetailsModal, show: true, key: new Date().toString(), foodCardCode }
                                }))
                              }
                            }
                          },
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: 'FA 號碼',
                  dataIndex: 'faCode',
                  key: 'faCode',
                  width: 150,
                },
                {
                  title: '食品卡號',
                  dataIndex: 'foodCardCode',
                  key: 'foodCardCode',
                  width: 200,
                },

                {
                  title: '姓氏',
                  dataIndex: 'lastname',
                  key: 'lastname',
                  width: 200,
                },

                {
                  title: '名字',
                  dataIndex: 'firstname',
                  key: 'firstname',
                  width: 200,
                },

                {
                  title: '中文姓名',
                  dataIndex: 'fullNameChinese',
                  key: 'fullNameChinese',
                  width: 200,
                },
                {
                  title: '電話號碼',
                  dataIndex: 'phone',
                  key: 'phone',
                  width: 120,
                  render: (value, record) => {
                    const [areaCode, phoneCode] = String((record.foodCardUserMemberFamily as GetFoodCardUserMemberFamilyRes).phone).split('-')
                    if (phoneCode) {
                      return phoneCode
                    }
                  }
                },

                {
                  title: '性別',
                  dataIndex: 'gender',
                  key: 'gender',
                  width: 80,
                  render: (value, record) => record.gender
                    ? record.gender === 'F'
                      ? (
                        <Tag color='pink'>
                          女
                        </Tag>
                      )
                      : (
                        <Tag color='blue'>
                          男
                        </Tag>
                      )
                    : undefined
                },
                // {
                //   title: '狀態',
                //   dataIndex: 'status',
                //   key: 'status',
                //   width: 100,
                //   render: (value, record) => {
                //     const findData = [
                //       { key: 'NORMAL', title: '正常', color: 'green' },
                //       { key: 'EXEMPTED', title: '豁免', color: 'blue' },
                //       { key: 'REFERRAL', title: '轉介中', color: 'purple' },
                //       { key: 'QUIT_SERVICE_DURING_WAITING', title: '輪候時已退出', color: 'red' },
                //     ].find(item => item.key === record.status)

                //     if (findData) {
                //       return (
                //         <Tag color={findData.color}>
                //           {findData.title}
                //         </Tag>
                //       )
                //     }
                //   }
                // },

                {
                  title: '出身日期',
                  dataIndex: 'dateOfBirth',
                  key: 'dateOfBirth',
                  width: 100,
                  render: (value) => value ? moment(value).format('YYYY-MM-DD') : `-`
                },

                {
                  title: '備註',
                  dataIndex: 'remark',
                  key: 'remark',
                  render: (value, record) => {
                    return (
                      <div
                        style={{
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {record.remark}
                      </div>
                    )
                  }
                },

                {
                  title: '家庭狀態',
                  dataIndex: 'familyStatus',
                  key: 'familyStatus',
                  width: 120,
                  render: (value, record) => {
                    return this._getStatusView((record.foodCardUserMemberFamily as GetFoodCardUserMemberFamilyRes).status || '')
                  }
                },

                {
                  title: '食品卡啟用時間',
                  dataIndex: 'foodCard.beginAt',
                  key: 'foodCard.beginAt',
                  width: 130,
                  render: (value, record) =>
                    record.foodCard?.beginAt
                      ? (<JJ_TableItemMoment
                        date={record.foodCard.beginAt}
                      />)
                      : '--:--'
                },
                {
                  title: '食品卡停用時間',
                  dataIndex: 'foodCard.endAt',
                  key: 'foodCard.endAt',
                  render: (value, record) =>
                    record.foodCard?.endAt
                      ? (<JJ_TableItemMoment
                        date={record.foodCard.endAt}
                      />)
                      : '--:--'
                },
                {
                  title: 'SFID',
                  dataIndex: 'sfid',
                  key: 'sfid',
                  width: 150,
                  render: (value) => (
                    !!value && <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '創建時間',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  width: 120,
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'用戶家庭成員列表'}
              tableProps={{
                scroll: {
                  x: 1800,
                }
              }}

              expandable={{
                expandedRowRender: (record) => this._oldSubTable(record),
                rowExpandable: (record) => record.olds && record.olds.length > 0
              }}

              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFoodCardUserMemberListParameters['isAscend']
                  beginAt?: GetFoodCardUserMemberListParameters['beginAt']
                  endAt?: GetFoodCardUserMemberListParameters['endAt']
                  status?: GetFoodCardUserMemberListParameters['status']
                  gender?: GetFoodCardUserMemberListParameters['gender']
                  foodCardUserMemberFamilyId?: GetFoodCardUserMemberListParameters['foodCardUserMemberFamilyId']
                  kioskVendingIds?: GetFoodCardUserMemberListParameters['kioskVendingIds']
                  foodStartRangeBeginAt?: GetFoodCardUserMemberListParameters['foodStartRangeBeginAt']
                  foodStartRangeEndAt?: GetFoodCardUserMemberListParameters['foodStartRangeEndAt']
                  foodStopRangeBeginAt?: GetFoodCardUserMemberListParameters['foodStopRangeBeginAt']
                  foodStopRangeEndAt?: GetFoodCardUserMemberListParameters['foodStopRangeEndAt']
                  dateOfBirthBeginAt?: GetFoodCardUserMemberListParameters['dateOfBirthBeginAt']
                  dateOfBirthEndAt?: GetFoodCardUserMemberListParameters['dateOfBirthEndAt']
                  familyStatus?: GetFoodCardUserMemberListParameters['familyStatus']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }

                  if (sourceItemBody.kioskVendingIds && sourceItemBody.kioskVendingIds.length > 0) {
                    params.kioskVendingIds = sourceItemBody.kioskVendingIds
                  }



                  const foodStartRange = sourceItemBody.foodStartRange
                  if (foodStartRange && foodStartRange.length === 2) {
                    const [foodStartRangeBeginAtMoment, foodStartRangeEndAtMoment,] = foodStartRange
                    params.foodStartRangeBeginAt = moment(foodStartRangeBeginAtMoment).startOf('day').toISOString()
                    params.foodStartRangeEndAt = moment(foodStartRangeEndAtMoment).endOf('day').toISOString()
                  }
                  const foodStopRange = sourceItemBody.foodStopRange
                  if (foodStopRange && foodStopRange.length === 2) {
                    const [foodStopRangeBeginAtMoment, foodStopRangeEndAtMoment,] = foodStopRange
                    params.foodStopRangeBeginAt = moment(foodStopRangeBeginAtMoment).startOf('day').toISOString()
                    params.foodStopRangeEndAt = moment(foodStopRangeEndAtMoment).endOf('day').toISOString()
                  }

                  const dateOfBirth = sourceItemBody.dateOfBirth
                  if (dateOfBirth && dateOfBirth.length === 2) {
                    const [dateOfBirthBeginAtMoment, dateOfBirthEndAtMoment,] = dateOfBirth
                    params.dateOfBirthBeginAt = moment(dateOfBirthBeginAtMoment).startOf('day').toISOString()
                    params.dateOfBirthEndAt = moment(dateOfBirthEndAtMoment).endOf('day').toISOString()
                  }


                  params.status = sourceItemBody.status
                  params.gender = sourceItemBody.gender

                  params.familyStatus = sourceItemBody.familyStatus

                }
                params.foodCardUserMemberFamilyId = this.props.foodCardUserMemberFamilyId

                const res = await api.GetFoodCardUserMemberList({
                  ...body,
                  ...params,
                  scope: ['foodCardUserMemberFamily'],
                })
                if (res.kind !== 'ok') throw new Error(res.kind)

                const totalCount = res.data.totalCount


                const data = await Promise.all(

                  res.data.data.map(async item => {


                    const familyOldListRes = await this._getFoodCardUserMemberFamilyOldList({ count: 1000, foodCardUserMemberId: item.id })

                    return {
                      ...item,
                      olds: await Promise.all(
                        familyOldListRes.data.map(async old => {

                          const foodCardListRes = await this._getFoodCardList({ count: 1, searchText: old.foodCardCode })

                          const [foodCard] = foodCardListRes.data

                          return {
                            ...old,
                            foodCard,
                          }

                        })
                      ),
                    }
                  })

                )

                return {
                  totalCount,
                  data,
                }
              }}
            />
          )}
        </Spin>


      </div>
    )
  }

}
export default connector(FoodCardUserMemberList)

