import React, { } from 'react';
import api, { GetSignupFormRes, GetSignupFormFamilyMemberRes, GetSignupFormFamilyMemberListRes, GetSignupFormParameters, GetSignupFormFamilyMemberListParameters, GetKioskVendingListParameters, GetKioskVendingRes, GetUserRes, PutSignupFormParameters, PostSignupFormResendExpiredSmsParameters, PutSignupFormDataParameters, PutSignupFormFamilyMemberParameters, DeleteSignupFormFamilyMemberParameters, GetFoodCardUserMemberFamilyRes, PostSignupFormReferredParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Spin, Alert, Image, Modal, message, Button, Layout, Carousel, Divider, Space, Typography, Tag, Switch, Descriptions, Form, Input, Select, Avatar, Checkbox, Card, Tooltip, Steps } from 'antd';
import { UnlockOutlined, CloseOutlined, CheckOutlined, EditOutlined, QuestionCircleOutlined, ClearOutlined, CheckCircleFilled, CloseCircleFilled, HistoryOutlined } from '@ant-design/icons';
import SignupFormDetailsModalRemarkEditor from './SignupFormDetailsModalRemarkEditor';
import SignupFormDetailsModalImageBrowserModal from './SignupFormDetailsModalImageBrowserModal';
import SignupFormDetailsModalFamilyMemberModal from './SignupFormDetailsModalFamilyMemberModal';
import SignupFormDetailsModalNotApprovedModal from './SignupFormDetailsModalNotApprovedModal';
import SignupFormDetailsModalHistoryModal from './SignupFormDetailsModalHistoryModal';
import axios from 'axios';
import moment from 'moment';

import config from '../../config';

const { Text, Paragraph, Link } = Typography;
const { Step } = Steps;

interface PageState {
  signupForm?: GetSignupFormRes
  signupFormFamilyMembers?: GetSignupFormFamilyMemberRes[]
  kioskVendings: GetKioskVendingRes[]
  images: Image[]
  spinning?: boolean
  imageBrowserModal: {
    key: string
    visible: boolean
    index: number
  }
  editFamilyMemberModal: {
    key: string
    visible: boolean
    id?: string
  }
  editNotApprovedModal: {
    key: string
    visible: boolean
  }
  editHistoryModal: {
    key?: string
    visible: boolean
  }
}

interface Image {
  id: string
  url: string
  thumbnailUrl: string
  label: string
  type: string
}

interface Props {
  title: string
  visible: boolean
  onCancel(): void
  signupFormId: string
  onOk(status: GetSignupFormRes['status'] | 'RESEND'): void
}

interface Page {

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

const formLayout = {
  labelCol: {
    md: { span: 5 },
    sm: { span: 24 },
  },
  wrapperCol: {
    md: { span: 16 },
  },
};


class SignupFormDetailsModal extends React.Component<PageProps, PageState> implements Page {

  constructor (props: Readonly<PageProps>) {
    super(props);

    this.state = {
      kioskVendings: [],
      signupFormFamilyMembers: [],
      images: [],
      imageBrowserModal: {
        key: new Date().toString(),
        visible: false,
        index: 0,
      },
      editFamilyMemberModal: {
        key: new Date().toString(),
        visible: false,
      },
      editNotApprovedModal: {
        key: new Date().toString(),
        visible: false,
      },
      editHistoryModal: {
        visible: false,
      },
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _getSignupForm = async (params: GetSignupFormParameters) => {
    const res = await api.GetSignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getSignupFormFamilyMemberList = async (params: GetSignupFormFamilyMemberListParameters) => {
    const res = await api.GetSignupFormFamilyMemberList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _putSignupForm = async (params: PutSignupFormParameters) => {
    const res = await api.PutSignupForm(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }
  _postSignupFormReferred = async (params: PostSignupFormReferredParameters) => {
    const res = await api.PostSignupFormReferred(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _postSignupFormResendExpiredSms = async (params: PostSignupFormResendExpiredSmsParameters) => {
    const res = await api.PostSignupFormResendExpiredSms(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _putSignupFormData = async (params: PutSignupFormDataParameters) => {
    const res = await api.PutSignupFormData(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _putSignupFormFamilyMember = async (params: PutSignupFormFamilyMemberParameters) => {
    const res = await api.PutSignupFormFamilyMember(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _deleteSignupFormFamilyMember = async (params: DeleteSignupFormFamilyMemberParameters) => {
    const res = await api.DeleteSignupFormFamilyMember(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _getKioskVendingList = async (params: GetKioskVendingListParameters) => {
    const res = await api.GetKioskVendingList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })

      const signupForm = await this._getSignupForm({ id: this.props.signupFormId, scope: ['user', 'operatedUser', 'foodCardUserMemberFamily'] })
      const signupFormFamilyMemberList = await this._getSignupFormFamilyMemberList({ signupFormId: this.props.signupFormId, isAscend: false })
      const kioskVendingList = await this._getKioskVendingList({ count: 1000 })

      let images = this._getAllImages(signupForm?.data, signupFormFamilyMemberList.data);


      images = await Promise.all(
        images.map(async image => {
          const contentType = await this._requestGetContentType(image.url);
          image.type = contentType
          return {
            ...image,
            type: contentType
          }
        })
      )

      this.setState({
        spinning: false,
        signupForm,
        signupFormFamilyMembers: signupFormFamilyMemberList.data,
        kioskVendings: kioskVendingList.data,
        images,
      })


    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };


  _requestGetContentType = async (uri: string): Promise<string> => {
    const res = await axios.get(uri, {
      withCredentials: true,
    });
    return res.headers['content-type'];
  };

  _getAllImages = (data: GetSignupFormRes['data'], members: GetSignupFormFamilyMemberListRes['data']): Image[] => {
    const addressProofImageIds = data?.addressProofImageIds || []
    const incomeProof = data?.incomeProof || {}

    const images: Image[] = []

    const formatImage = (id: string, label: string) => ({
      id,
      url: `${config.REACT_APP_API}/v2/admin/signup-form/${this.props.signupFormId}/image/${id}`,
      thumbnailUrl: `${config.REACT_APP_API}/v2/admin/signup-form/${this.props.signupFormId}/image/${id}?thumbnail=true`,
      label,
      type: '',
    }) as Image

    if (data) {
      members.forEach((member, i) => {
        if (member && member?.idCardImageIds && member?.idCardImageIds[0]) {
          images.push(formatImage(member?.idCardImageIds[0], `身份證正面照片 (第 ${i + 1} 位成員: ${member.lastname} ${member.firstname})`))
        }
      });

      if (addressProofImageIds[0]) {
        images.push(formatImage(addressProofImageIds[0], '住址證明'))
      }

      if (incomeProof.periodBefore && incomeProof.periodBefore?.imageIds) {
        incomeProof.periodBefore?.imageIds.forEach((image, j) => {
          images.push(formatImage(image, `受疫情影響前的家庭入息 (${j + 1})`))
        })
      }

      if (incomeProof.periodAfter1 && incomeProof.periodAfter1?.imageIds) {
        incomeProof.periodAfter1?.imageIds.forEach((image, j) => {
          images.push(formatImage(image, `受疫情影響後 及 申請服務時最近3個月的家庭入息 (${j + 1})`))
        })
      }

      if (incomeProof.periodAfter2 && incomeProof.periodAfter2?.imageIds) {
        incomeProof.periodAfter2?.imageIds.forEach((image, j) => {
          images.push(formatImage(image, `受疫情影響後 及 申請服務時最近2個月的家庭入息 (${j + 1})`))
        })
      }

      if (incomeProof.periodAfter3 && incomeProof.periodAfter3?.imageIds) {
        incomeProof.periodAfter3?.imageIds.forEach((image, j) => {
          images.push(formatImage(image, `受疫情影響後 及 申請服務時最近1個月的家庭入息 (${j + 1})`))
        })
      }
    }

    return images
  }

  _imageBrowsable = (id: string, label: string) => {
    const index = this.state.images.findIndex(image => image.id === id)

    return (
      <Image
        key={index}
        src={this.state.images[index].thumbnailUrl}
        preview={false}
        className="signup-form-detail-image"
        onClick={() => {
          this.setState({
            imageBrowserModal: {
              ...this.state.imageBrowserModal,
              key: new Date().toString(),
              visible: true,
              index,
            },
          })
        }}
      />
    )
  }

  _clearImageButton = (title: string, getData: () => any) => {
    return (
      <Button
        type="dashed"
        danger
        icon={<ClearOutlined />}
        onClick={() => {
          Modal.confirm({
            title: '確定刪除',
            content: `確定要刪除 ${title} 的所有文件? 申請人需要重新上傳文件才能繼續審批`,
            okType: 'danger',
            onOk: async () => {
              try {
                this.setState({
                  spinning: true
                })

                await this._putSignupFormData({
                  id: this.props.signupFormId,
                  ...getData(),
                });

                this._onLoadData()

              } catch (error) {
                this.setState({
                  spinning: false
                })
                console.log(error)
              }
            },
          });
        }}
      >
        刪除所有文件
      </Button>
    )
  }

  _clearIdCardImageButton = (member: GetSignupFormFamilyMemberRes) => {
    return (
      <Button
        type="dashed"
        danger
        icon={<ClearOutlined />}
        onClick={() => {
          Modal.confirm({
            title: '確定刪除',
            content: `確定要刪除 ${member.lastname} ${member.firstname} 的所有身份證文件? 申請人需要重新上傳文件才能繼續審批`,
            okType: 'danger',
            onOk: async () => {
              try {
                this.setState({
                  spinning: true
                })

                await this._putSignupFormFamilyMember({
                  id: member.id,
                  idCardImageIds: [],
                });

                this._onLoadData()

              } catch (error) {
                this.setState({
                  spinning: false
                })
                console.log(error)
              }
            },
          });
        }}
      >
        刪除所有文件
      </Button>
    )
  }

  _approveIdCardImageButton = (member: GetSignupFormFamilyMemberRes) => {
    return (
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={() => {
          Modal.confirm({
            title: '確定已認證並刪除文件',
            content: `確定已認證 ${member.lastname} ${member.firstname} 的身份證文件並刪除文件?`,
            okType: 'danger',
            onOk: async () => {
              try {
                this.setState({
                  spinning: true
                })

                await this._putSignupFormFamilyMember({
                  id: member.id,
                  idCardImageIds: [],
                  idCardChecked: true,
                });

                this._onLoadData()

              } catch (error) {
                this.setState({
                  spinning: false
                })
                console.log(error)
              }
            },
          });
        }}
      >
        標記為已認證並刪除文件
      </Button>
    )
  }

  _getFormItemFeedback = (success: boolean) => {
    return success ? <CheckCircleFilled style={{ color: '#52c41a' }} /> : <CloseCircleFilled style={{ color: '#ff4d4f' }} />
  }

  _getStatusItemView = (status: GetSignupFormRes['status']) => {
    switch (status) {
      case 'APPROVED': {
        return <Tag color='success'>已審批</Tag>
      }
      case 'DRAFT': {
        return <Tag color='default'>未完成</Tag>
      }
      case 'EXPIRED': {
        return <Tag color='red'>已過期</Tag>
      }
      case 'REJECTED': {
        return <Tag color="red">已拒絕</Tag>
      }
      case 'NOT_APPROVED': {
        return <Tag color="red">已不批准</Tag>
      }
      case 'SELECTED_EFOOD_BANK': {
        return <Tag color="red">已選 E+ 食</Tag>
      }
      case 'SELECTED_HOT_MEAL': {
        return <Tag color="red">已選趁熱食</Tag>
      }
      case 'SUBMITTED': {
        return <Tag color='green'>已提交</Tag>
      }
      case 'ACCEPTING': {
        return <Tag color='green'>已接纳 (同步中)</Tag>
      }
      case 'ACCEPTED': {
        return <Tag color='green'>已接纳</Tag>
      }
      case 'REFERRED': {
        return <Tag color='red'>已轉介</Tag>
      }
    }
  }

  _incomeProofView = (data: GetSignupFormRes['data']) => {
    const incomeProof = data?.incomeProof
    const members = this.state.signupFormFamilyMembers

    if (incomeProof) {
      const incomeSum = (incomeProof.periodAfter1?.amount || 0) + (incomeProof.periodAfter2?.amount || 0) + (incomeProof.periodAfter3?.amount || 0);

      /*
      1 person: below $11,000
      2 persons: below $18,700
      3 persons: below $22,360
      4 persons: below $26,100
      5 persons: below $26,880
      6 persons: below $29,400
      7 persons or above: below $29,400
      */
      let incomeCondition = 0;
      if (members) {
        if (members.length >= 6) {
          incomeCondition = 29400
        } else if (members.length === 5) {
          incomeCondition = 26880
        } else if (members.length === 4) {
          incomeCondition = 26100
        } else if (members.length === 3) {
          incomeCondition = 22360
        } else if (members.length === 2) {
          incomeCondition = 18700
        } else if (members.length === 1) {
          incomeCondition = 11000
        }
      }

      return (
        <Descriptions
          title={(
            <>
              <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>C</Avatar>
              <span>收入證明</span>
            </>
          )}
          size="small"
          bordered
          column={1}
        >
          <Descriptions.Item
            label="最近3個月家庭入息平均數"
          >
            <Form.Item
              {...formLayout}
              label="平均數"
            >
              (${incomeProof.periodAfter1?.amount || 0} + ${incomeProof.periodAfter2?.amount || 0} + ${incomeProof.periodAfter3?.amount || 0}) / 3 = <Text strong>${(incomeSum / 3).toFixed(0)}</Text>
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息需求"
            >
              <Text strong>${incomeCondition}</Text> ({members?.length} 人)
              <Tooltip title={(
                <>
                  <li>1 person: below $11,000</li>
                  <li>2 persons: below $18,700</li>
                  <li>3 persons: below $22,360</li>
                  <li>4 persons: below $26,100</li>
                  <li>5 persons: below $26,880</li>
                  <li>6 persons: below $29,400</li>
                  <li>7 persons or above: below $29,400</li>
                </>
              )}>
                <Text type="secondary" style={{ marginLeft: 8 }}><QuestionCircleOutlined /></Text>
              </Tooltip>
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息結果"
            >
              {this._getFormItemFeedback(Math.floor(incomeSum / 3) <= incomeCondition)}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="受疫情影響前的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
              hasFeedback
              validateStatus={incomeProof.periodBefore?.year && incomeProof.periodBefore?.month ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodBefore?.year || '-'} / ${incomeProof.periodBefore?.month || '-'}`}
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodBefore?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodBefore?.amount === undefined ? '-' : incomeProof.periodBefore?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodBefore && incomeProof.periodBefore?.imageIds && incomeProof.periodBefore?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodBefore?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (受疫情影響前)'))}</div>

              {incomeProof.periodBefore?.imageIds && incomeProof.periodBefore?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (受疫情影響前)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodBefore: {
                      ...data?.incomeProof?.periodBefore,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="受疫情影響後 及 申請服務時最近3個月的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
            >
              {incomeProof.periodAfter1?.year || '-'} / {incomeProof.periodAfter1?.month || '-'}
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodAfter1?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodAfter1?.amount === undefined ? '-' : incomeProof.periodAfter1?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodAfter1 && incomeProof.periodAfter1?.imageIds && incomeProof.periodAfter1?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodAfter1?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (最近3個月)'))}</div>

              {incomeProof.periodAfter1?.imageIds && incomeProof.periodAfter1?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (最近3個月)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodAfter1: {
                      ...data?.incomeProof?.periodAfter1,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="受疫情影響後 及 申請服務時最近2個月的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
            >
              {incomeProof.periodAfter2?.year || '-'} / {incomeProof.periodAfter2?.month || '-'}
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodAfter2?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodAfter2?.amount === undefined ? '-' : incomeProof.periodAfter2?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodAfter2 && incomeProof.periodAfter2?.imageIds && incomeProof.periodAfter2?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodAfter2?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (最近2個月)'))}</div>

              {incomeProof.periodAfter2?.imageIds && incomeProof.periodAfter2?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (最近2個月)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodAfter2: {
                      ...data?.incomeProof?.periodAfter2,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="受疫情影響後 及 申請服務時最近1個月的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
            >
              {incomeProof.periodAfter3?.year || '-'} / {incomeProof.periodAfter3?.month || '-'}
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodAfter3?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodAfter3?.amount === undefined ? '-' : incomeProof.periodAfter3?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodAfter3 && incomeProof.periodAfter3?.imageIds && incomeProof.periodAfter3?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodAfter3?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (最近1個月)'))}</div>

              {incomeProof.periodAfter3?.imageIds && incomeProof.periodAfter3?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (最近1個月)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodAfter3: {
                      ...data?.incomeProof?.periodAfter3,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="若以上任何月份未能提供文件，請列明原因 （可選多個一項）"
          >
            <Form.Item
              {...formLayout}
              label="未能提供原因"
            >
              <Checkbox.Group
                options={[
                  {
                    label: '沒有收入',
                    value: 0,
                  },
                  {
                    label: '現金出糧',
                    value: 1,
                  },
                  {
                    label: '支票出糧',
                    value: 2,
                  },
                  {
                    label: '自僱人士',
                    value: 3,
                  },
                  {
                    label: '遺失糧單',
                    value: 4,
                  },
                  {
                    label: '遺失月結單',
                    value: 6,
                  },
                  {
                    label: '其他（請填寫原因）',
                    value: 5,
                  },
                ]}
                value={incomeProof?.exceptionReasonIndices}
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="其他原因"
              hasFeedback
              validateStatus={incomeProof && incomeProof?.exceptionReasonIndices && (incomeProof?.exceptionReasonIndices.indexOf(5) === -1 || incomeProof?.exceptionReason) ? 'success' : 'error'}
            >
              <Input
                value={(incomeProof && incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.indexOf(5) > -1 && incomeProof?.exceptionReason) || '-'}
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="收入申報聲明"
          >
            <Form.Item
              {...formLayout}
              label="申請人全名"
              hasFeedback
              validateStatus={incomeProof?.fullName ? 'success' : 'error'}
            >
              <Input
                value={incomeProof?.fullName || '-'}
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="身份證號碼"
            >
              <Input.Group compact>
                <Input
                  style={{ width: 150 }}
                  value={incomeProof?.idCard?.card || ''}
                  placeholder="XXXXXX"
                />
                <span style={{ marginLeft: 4, marginRight: 4 }}>(</span>
                <Input
                  style={{ width: 50 }}
                  value={incomeProof?.idCard?.checkDigit || ''}
                  placeholder="X"
                />
                <span style={{ marginLeft: 4, marginRight: 4 }}>)</span>

                {this._getFormItemFeedback(!!incomeProof?.idCard?.card && !!incomeProof?.idCard?.checkDigit)}
              </Input.Group>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      )
    }

    return (<div />)

  }

  _incomeProofRenewalView = (data: GetSignupFormRes['data'], memberCount: number) => {
    const incomeProof = data?.incomeProof

    if (incomeProof) {
      const incomeSum = (incomeProof.periodAfter1?.amount || 0) + (incomeProof.periodAfter2?.amount || 0) + (incomeProof.periodAfter3?.amount || 0);

      /*
      1 person: below $11,000
      2 persons: below $18,700
      3 persons: below $22,360
      4 persons: below $26,100
      5 persons: below $26,880
      6 persons: below $29,400
      7 persons or above: below $29,400
      */
      let incomeCondition = 0;
      if (memberCount >= 6) {
        incomeCondition = 29400
      } else if (memberCount === 5) {
        incomeCondition = 26880
      } else if (memberCount === 4) {
        incomeCondition = 26100
      } else if (memberCount === 3) {
        incomeCondition = 22360
      } else if (memberCount === 2) {
        incomeCondition = 18700
      } else if (memberCount === 1) {
        incomeCondition = 11000
      }

      return (
        <Descriptions
          title={(
            <>
              <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>A</Avatar>
              <span>收入證明</span>
            </>
          )}
          size="small"
          bordered
          column={1}
        >
          <Descriptions.Item
            label="最近3個月家庭入息平均數"
          >
            <Form.Item
              {...formLayout}
              label="平均數"
            >
              (${incomeProof.periodAfter1?.amount || 0} + ${incomeProof.periodAfter2?.amount || 0} + ${incomeProof.periodAfter3?.amount || 0}) / 3 = <Text strong>${(incomeSum / 3).toFixed(0)}</Text>
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息需求"
            >
              <Text strong>${incomeCondition}</Text> ({memberCount} 人)
              <Tooltip title={(
                <>
                  <li>1 person: below $11,000</li>
                  <li>2 persons: below $18,700</li>
                  <li>3 persons: below $22,360</li>
                  <li>4 persons: below $26,100</li>
                  <li>5 persons: below $26,880</li>
                  <li>6 persons: below $29,400</li>
                  <li>7 persons or above: below $29,400</li>
                </>
              )}>
                <Text type="secondary" style={{ marginLeft: 8 }}><QuestionCircleOutlined /></Text>
              </Tooltip>
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息結果"
            >
              {this._getFormItemFeedback(Math.floor(incomeSum / 3) <= incomeCondition)}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="最近3個月的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
            >
              {incomeProof.periodAfter1?.year || '-'} / {incomeProof.periodAfter1?.month || '-'}
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodAfter1?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodAfter1?.amount === undefined ? '-' : incomeProof.periodAfter1?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodAfter1 && incomeProof.periodAfter1?.imageIds && incomeProof.periodAfter1?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodAfter1?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (最近3個月)'))}</div>

              {incomeProof.periodAfter1?.imageIds && incomeProof.periodAfter1?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (最近3個月)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodAfter1: {
                      ...data?.incomeProof?.periodAfter1,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="最近2個月的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
            >
              {incomeProof.periodAfter2?.year || '-'} / {incomeProof.periodAfter2?.month || '-'}
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodAfter2?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodAfter2?.amount === undefined ? '-' : incomeProof.periodAfter2?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodAfter2 && incomeProof.periodAfter2?.imageIds && incomeProof.periodAfter2?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodAfter2?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (最近2個月)'))}</div>

              {incomeProof.periodAfter2?.imageIds && incomeProof.periodAfter2?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (最近2個月)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodAfter2: {
                      ...data?.incomeProof?.periodAfter2,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="最近1個月的家庭入息"
          >
            <Form.Item
              {...formLayout}
              label="時段"
            >
              {incomeProof.periodAfter3?.year || '-'} / {incomeProof.periodAfter3?.month || '-'}
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="家庭入息金額"
              hasFeedback
              validateStatus={incomeProof.periodAfter3?.amount !== undefined ? 'success' : 'error'}
            >
              <Input
                value={`${incomeProof.periodAfter3?.amount === undefined ? '-' : incomeProof.periodAfter3?.amount}`}
                prefix="$"
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="入息證明圖片"
              hasFeedback
              validateStatus={incomeProof && (incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.length > 0) || (incomeProof.periodAfter3 && incomeProof.periodAfter3?.imageIds && incomeProof.periodAfter3?.imageIds.length > 0) ? 'success' : 'error'}
            >
              <div>{incomeProof.periodAfter3?.imageIds?.map(imageId => this._imageBrowsable(imageId, '入息證明圖片 (最近1個月)'))}</div>

              {incomeProof.periodAfter3?.imageIds && incomeProof.periodAfter3?.imageIds?.length > 0 && this._clearImageButton('入息證明圖片 (最近1個月)', () => {
                return {
                  ...data,
                  incomeProof: {
                    ...data?.incomeProof,
                    periodAfter3: {
                      ...data?.incomeProof?.periodAfter3,
                      imageIds: [],
                    },
                  },
                }
              })}
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="若以上任何月份未能提供文件，請列明原因 （可選多個一項）"
          >
            <Form.Item
              {...formLayout}
              label="未能提供原因"
            >
              <Checkbox.Group
                options={[
                  {
                    label: '沒有收入',
                    value: 0,
                  },
                  {
                    label: '現金出糧',
                    value: 1,
                  },
                  {
                    label: '支票出糧',
                    value: 2,
                  },
                  {
                    label: '自僱人士',
                    value: 3,
                  },
                  {
                    label: '遺失糧單',
                    value: 4,
                  },
                  {
                    label: '遺失月結單',
                    value: 6,
                  },
                  {
                    label: '其他（請填寫原因）',
                    value: 5,
                  },
                ]}
                value={incomeProof?.exceptionReasonIndices}
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="其他原因"
              hasFeedback
              validateStatus={incomeProof && incomeProof?.exceptionReasonIndices && (incomeProof?.exceptionReasonIndices.indexOf(5) === -1 || incomeProof?.exceptionReason) ? 'success' : 'error'}
            >
              <Input
                value={(incomeProof && incomeProof?.exceptionReasonIndices && incomeProof?.exceptionReasonIndices.indexOf(5) > -1 && incomeProof?.exceptionReason) || '-'}
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item
            label="收入申報聲明"
          >
            <Form.Item
              {...formLayout}
              label="申請人全名"
              hasFeedback
              validateStatus={incomeProof?.fullName ? 'success' : 'error'}
            >
              <Input
                value={incomeProof?.fullName || '-'}
              />
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="身份證號碼"
            >
              <Input.Group compact>
                <Input
                  style={{ width: 150 }}
                  value={incomeProof?.idCard?.card || ''}
                  placeholder="XXXXXX"
                />
                <span style={{ marginLeft: 4, marginRight: 4 }}>(</span>
                <Input
                  style={{ width: 50 }}
                  value={incomeProof?.idCard?.checkDigit || ''}
                  placeholder="X"
                />
                <span style={{ marginLeft: 4, marginRight: 4 }}>)</span>

                {this._getFormItemFeedback(!!incomeProof?.idCard?.card && !!incomeProof?.idCard?.checkDigit)}
              </Input.Group>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      )
    }

    return (<div />)

  }

  _membersView = (data: GetSignupFormRes['data'], status: GetSignupFormRes['status']) => {
    const members = this.state.signupFormFamilyMembers || []
    return (
      <Descriptions
        title={(
          <>
            <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>A</Avatar>
            <span>家庭成員</span>
          </>
        )}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="申請家庭人數">
          <span style={{ marginRight: 8 }}>{members.length}</span>
          {this._getFormItemFeedback(members.length > 0)}
        </Descriptions.Item>
        {members.map((member, index) => {
          let gender = '-';
          if (member.gender) {
            switch (member.gender) {
              case 'M': {
                gender = '男';
                break;
              }
              case 'F': {
                gender = '女';
                break;
              }
            }
          }

          return (
            <Descriptions.Item
              key={index}
              label={
                <>
                  <div>
                    第 {index + 1} 位成員
                  </div>
                  {['DRAFT', 'SUBMITTED', 'EXPIRED'].indexOf(status) > -1 && (
                    <Space>
                      <Button
                        type="default"
                        shape="round"
                        icon={<EditOutlined />}
                        onClick={() => {
                          this.setState({
                            editFamilyMemberModal: {
                              ...this.state.editFamilyMemberModal,
                              key: new Date().toString(),
                              visible: true,
                              id: member.id,
                            },
                          })
                        }}
                      >
                        編輯
                      </Button>

                      {members.length > 1 && (
                        <Button
                          type="default"
                          shape="round"
                          danger
                          icon={<CloseOutlined />}
                          onClick={() => {
                            Modal.confirm({
                              title: '確定刪除成員',
                              content: `確定要刪除成員 ${member.lastname} ${member.firstname} (${member.fullNameChinese})?`,
                              okType: 'danger',
                              onOk: async () => {
                                try {
                                  this.setState({
                                    spinning: true
                                  })

                                  await this._deleteSignupFormFamilyMember({
                                    id: member.id,
                                  });

                                  this._onLoadData()

                                } catch (error) {
                                  this.setState({
                                    spinning: false
                                  })
                                  console.log(error)
                                }
                              },
                            });
                          }}
                        >
                          刪除
                        </Button>
                      )}
                    </Space>
                  )}
                </>
              }
            >
              <Form.Item
                {...formLayout}
                label="成員英文姓氏"
              >
                {member.lastname}
              </Form.Item>

              <Form.Item
                {...formLayout}
                label="成員英文名字"
              >
                {member.firstname}
              </Form.Item>

              <Form.Item
                {...formLayout}
                label="成員中文全名"
              >
                {member.fullNameChinese}
              </Form.Item>

              <Form.Item
                {...formLayout}
                label="性別"
              >
                {gender}
              </Form.Item>

              <Form.Item
                {...formLayout}
                label="出生日期"
              >
                {moment(member.dateOfBirth).format('YYYY-MM-DD')}
              </Form.Item>

              <Form.Item
                {...formLayout}
                label="HKID前四位"
              >
                {member.hkidPrefix}
              </Form.Item>

              <Form.Item
                {...formLayout}
                label="身份證正面照片"
                hasFeedback
                validateStatus={member && (member?.idCardChecked || (member?.idCardImageIds && member?.idCardImageIds[0])) ? 'success' : 'error'}
              >
                {member && !member?.idCardChecked && member?.idCardImageIds && member?.idCardImageIds[0] && (
                  <div>
                    {this._imageBrowsable(member?.idCardImageIds[0], '身份證正面照片')}
                  </div>
                )}
                {member && member?.idCardChecked && (
                  <div style={{ width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', border: '1px solid #d9d9d9' }}>
                    <CheckOutlined style={{ color: '#52c41a' }} />
                    <div>文件已認證</div>
                  </div>
                )}

                <Space>
                  {member && member?.idCardImageIds && member?.idCardImageIds[0] && this._clearIdCardImageButton(member)}

                  {member && member?.idCardImageIds && member?.idCardImageIds[0] && this._approveIdCardImageButton(member)}
                </Space>
              </Form.Item>
            </Descriptions.Item>
          )
        })}
      </Descriptions>
    )
  }



  _addressProofImageView = (data: GetSignupFormRes['data']) => {
    const addressProofImageIds = data?.addressProofImageIds || []
    return (
      <Descriptions
        title={(
          <>
            <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>B</Avatar>
            <span>住址證明</span>
          </>
        )}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="住址證明圖片">
          <Form.Item
            {...formLayout}
            hasFeedback
            validateStatus={addressProofImageIds[0] ? 'success' : 'error'}
          >
            <div>{addressProofImageIds[0] && this._imageBrowsable(addressProofImageIds[0], '住址證明圖片')}</div>

            {addressProofImageIds[0] && this._clearImageButton('住址證明圖片', () => {
              return {
                ...data,
                addressProofImageIds: [],
              }
            })}
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>
    )
  }


  _preferredKioskView = (data: GetSignupFormRes['data']) => {
    const preferredKioskId = data?.preferredKioskId
    const kioskVending = this.state.kioskVendings.find(kioskVending => kioskVending.id === preferredKioskId)
    return (
      <Descriptions
        title={(
          <>
            <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>E</Avatar>
            <span>選擇首選自助機</span>
          </>
        )}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="首選自助機">
          {kioskVending ? (
            <>
              <div>
                <span style={{ marginRight: 8 }}>{kioskVending.name}</span>
                <Tag color="processing">{kioskVending.code}</Tag>
                {this._getFormItemFeedback(true)}
              </div>
              <Text type="secondary">{kioskVending.address}</Text>
            </>
          ) : this._getFormItemFeedback(false)}
        </Descriptions.Item>
      </Descriptions>
    )
  }


  _octopusCardsView = (data: GetSignupFormRes['data']) => {
    let octopusCardsNoneChecked = data?.octopusCardsNoneChecked || false
    let octopusCards = data?.octopusCards && !octopusCardsNoneChecked ? data?.octopusCards.filter((octopusCard, i) => i === 0 || (!!octopusCard.card && (!!octopusCard.noCheckDigitChecked || !!octopusCard.checkDigit))) : []
    return (
      <Descriptions
        title={(
          <>
            <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>D</Avatar>
            <span>登記八達通會員卡</span>
          </>
        )}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="八達通數量">
          <span style={{ marginRight: 8 }}>{octopusCards.length}</span>
          {this._getFormItemFeedback(octopusCards.length > 0 || octopusCardsNoneChecked)}
        </Descriptions.Item>
        {octopusCards.map((octopusCard, index) => (
          <Descriptions.Item
            key={index}
            label={`第 ${index + 1} 個八達通`}
          >
            <Input.Group compact>
              <Input
                style={{ width: 150 }}
                value={octopusCard.card || ''}
                placeholder="XXXXXXXX"
              />
              <span style={{ marginLeft: 4, marginRight: 4 }}>(</span>
              <Input
                style={{ width: 50 }}
                value={octopusCard.noCheckDigitChecked ? '' : (octopusCard.checkDigit !== undefined ? octopusCard.checkDigit : '')}
                placeholder="X"
              />
              <span style={{ marginLeft: 4, marginRight: 4 }}>)</span>

              {this._getFormItemFeedback(!!octopusCard.card && (!!octopusCard.noCheckDigitChecked || !!octopusCard.checkDigit))}
            </Input.Group>
          </Descriptions.Item>
        ))}
      </Descriptions>
    )
  }


  _foodSafetyAndPersonalInfoClaimCheckedView = (data: GetSignupFormRes['data']) => {
    return (
      <Descriptions
        title={(
          <>
            <Avatar style={{ backgroundColor: '#87d068', marginRight: 8 }}>F</Avatar>
            <span>食安及個人資料收集聲明</span>
          </>
        )}
        size="small"
        bordered
        column={1}
      >
        <Descriptions.Item label="食安聲明">
          <Checkbox
            checked={data?.foodSafetyChecked}
          >
            已閱讀並同意食安聲明
          </Checkbox>

          {this._getFormItemFeedback(!!data?.foodSafetyChecked)}
        </Descriptions.Item>

        <Descriptions.Item label="個人資料收集聲明">
          <Checkbox
            checked={data?.personalInfoClaimChecked}
          >
            已閱讀並同意個人資料收集聲明
          </Checkbox>

          {this._getFormItemFeedback(!!data?.personalInfoClaimChecked)}
        </Descriptions.Item>
      </Descriptions>
    )

  }


  _view = (signupForm?: GetSignupFormRes) => {
    if (signupForm) {
      const user = (signupForm.user as GetUserRes)
      const operatedUser = (signupForm.operatedUser as GetUserRes)
      const data = signupForm.data

      let statusStep = 1;
      if (signupForm) {
        if (signupForm.firstOpenedAt) {
          statusStep = 2;
        }
        if (['REJECTED', 'SELECTED_HOT_MEAL', 'SELECTED_EFOOD_BANK'].indexOf(signupForm.status) > -1) {
          statusStep = 2;
        }
        if (['SUBMITTED', 'NOT_APPROVED'].indexOf(signupForm.status) > -1) {
          statusStep = 3
        }
        if (['APPROVED'].indexOf(signupForm.status) > -1) {
          statusStep = 4;
        }
        if (['ACCEPTING', 'ACCEPTED'].indexOf(signupForm.status) > -1) {
          statusStep = 5;
        }
      }

      return (
        <Layout
          style={{
            backgroundColor: 'white',
          }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            <Descriptions
              size="small"
              column={1}
              bordered
            >
              <Descriptions.Item
                label="JC Console"
              >
                <Space>
                  <span>{signupForm.caseNumCustom || '-'}</span>
                  <Button
                    type="ghost"
                    shape="round"
                    onClick={() => this._onButtonHistory()}
                    icon={<HistoryOutlined />}
                  >
                    檢查歷史
                  </Button>
                </Space>
              </Descriptions.Item>

              <Descriptions.Item
                label="申請人電話號碼"
              >
                {signupForm.contactMobile ? signupForm.contactMobile.replace('+852-', '') : '-'}
              </Descriptions.Item>

              <Descriptions.Item
                label="申請類別"
              >
                {signupForm.isRenewal ? (
                  <Tag color='red'>續期</Tag>
                ) : (
                    <Tag>首次</Tag>
                  )}
              </Descriptions.Item>

              <Descriptions.Item
                label="申請狀態"
              >
                {this._getStatusItemView(signupForm.status)}
              </Descriptions.Item>

              <Descriptions.Item
                label="申請日期"
              >
                <Tooltip title={moment(signupForm.createdAt).fromNow()}>
                  {moment(signupForm.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </Tooltip>
              </Descriptions.Item>

              <Descriptions.Item
                label="用戶地址"
              >
                {signupForm.addressLine}
              </Descriptions.Item>

              {!signupForm.isRenewal &&
                signupForm.status !== 'REJECTED' &&
                signupForm.status !== 'NOT_APPROVED' &&
                ((signupForm.foodCardUserMemberFamily as GetFoodCardUserMemberFamilyRes)?.status !== 'TERMINATED') ?
                (
                  <Descriptions.Item
                    label="備註"
                  >
                    <SignupFormDetailsModalRemarkEditor
                      signupFormId={signupForm.id}
                      value={signupForm.remark}
                      onChange={(value) => this._onLoadData()}
                    />
                  </Descriptions.Item>
                ) : (
                  <Paragraph
                    style={{ whiteSpace: 'pre-wrap' }}
                  >
                    {signupForm.remark}
                  </Paragraph>
                )
              }

              <Descriptions.Item
                label="申請狀態"
              >
                <Steps
                  direction="vertical"
                  style={{ padding: 16 }}
                  current={statusStep}
                  status={['REJECTED', 'SELECTED_HOT_MEAL', 'SELECTED_EFOOD_BANK', 'NOT_APPROVED', 'EXPIRED', 'REFERRED'].indexOf(signupForm.status) > -1 ? 'error' : 'process'}
                >
                  <Step
                    title="發送短訊"
                    description="申請已建立, 並發送短訊通知申請人填寫資料"
                    subTitle={`(於 ${moment(signupForm.createdAt).format('YYYY-MM-DD HH:mm:ss')} 發送)`}
                  />
                  <Step
                    title="申請人打開連結"
                    description="點擊連結並輸入正確驗證碼"
                    subTitle={statusStep >= 2 ? `(於 ${moment(signupForm.firstOpenedAt).format('YYYY-MM-DD HH:mm:ss')} 打開)` : null}
                  />
                  <Step
                    title={(() => {
                      switch (signupForm.status) {
                        case 'REJECTED':
                          return `提交表格 (已選拒絕)`;
                        case 'SELECTED_HOT_MEAL':
                          return `提交表格 (已選趁熱食)`;
                        case 'SELECTED_EFOOD_BANK':
                          return `提交表格 (已選 E+ 食)`;
                        case 'REFERRED':
                          return `提交表格 (已轉介)`;
                      }
                      return '提交表格';
                    })()}
                    description={signupForm.submittedAt ? '資料已提交' : '資料未曾提交'}
                    subTitle={signupForm.submittedAt ? `(於 ${moment(signupForm.submittedAt).format('YYYY-MM-DD HH:mm:ss')} 提交)` : `(將於 ${moment(signupForm.expiredAt).format('YYYY-MM-DD')} 過期)`}
                  />
                  <Step
                    title={(() => {
                      switch (signupForm.status) {
                        case 'NOT_APPROVED':
                          return `處理審核 (已不批准)`;
                        case 'REFERRED':
                          return `處理審核 (已轉介)`;
                      }
                      return '處理審核';
                    })()}
                    description="管理員審核或拒絕申請"
                    subTitle={['APPROVED', 'NOT_APPROVED'].indexOf(signupForm.status) > -1 || statusStep >= 4 ? `(於 ${moment(signupForm.operatedAt).format('YYYY-MM-DD HH:mm:ss')} 由 ${operatedUser ? operatedUser.nickname : '-'} 操作)` : null}
                  />
                  <Step
                    title="處理接納"
                    description="管理員完成接納, 並自動生成食品卡給此申請家庭"
                  />
                </Steps>
              </Descriptions.Item>
            </Descriptions>

            {(signupForm.status === 'DRAFT' || signupForm.status === 'EXPIRED') && (
              <Alert
                type="warning"
                message="用戶尚未完成提交此表格"
                showIcon
              />
            )}

            {signupForm.status === 'REFERRED' && (
              <Alert
                type="error"
                message="管理員已轉介此申請"
                showIcon
              />
            )}
            {signupForm.status === 'REJECTED' && (
              <Alert
                type="error"
                message="用戶已確定終止「自在食」計劃申請"
                showIcon
              />
            )}

            {signupForm.status === 'SELECTED_EFOOD_BANK' && (
              <Alert
                type="error"
                message="用戶已確定申請「趁熱食」服務"
                showIcon
              />
            )}

            {signupForm.status === 'SELECTED_HOT_MEAL' && (
              <Alert
                type="error"
                message="用戶已確定想申請短期服務「E+ 食」請"
                showIcon
              />
            )}

            {(signupForm.status === 'SUBMITTED') && (
              <Alert
                type="info"
                message="用戶已完成提交此表格, 等待管理員處理審核"
                showIcon
              />
            )}

            {(signupForm.status === 'APPROVED') && (
              <Alert
                type="info"
                message="管理員已審核此申請, 等待處理接納"
                showIcon
              />
            )}

            {signupForm.status === 'NOT_APPROVED' && (
              <Alert
                type="error"
                message="管理員已不批准此申請"
                showIcon
              />
            )}

            {(signupForm.status === 'ACCEPTING') && (
              <Alert
                type="success"
                message="管理員已接納此申請, 等待系統同步中"
                showIcon
              />
            )}

            {(signupForm.status === 'ACCEPTED') && (
              <Alert
                type="success"
                message="管理員已接納此申請, 食品卡已生成"
                showIcon
              />
            )}

            {['REJECTED', 'SELECTED_EFOOD_BANK', 'SELECTED_HOT_MEAL'].indexOf(signupForm.status) === -1 && (
              <Card
                title="申請個案內容"
                style={{ marginTop: 16 }}
              >
                {signupForm.isRenewal ? [
                  this._incomeProofRenewalView(data, signupForm.memberIds ? signupForm.memberIds.length : 0),
                ] : [
                    this._membersView(data, signupForm.status),
                    this._addressProofImageView(data),
                    this._incomeProofView(data),
                    this._octopusCardsView(data),
                    this._preferredKioskView(data),
                    this._foodSafetyAndPersonalInfoClaimCheckedView(data),
                  ]}
              </Card>
            )}
          </Space>
        </Layout>
      )
    }

    return <div style={{ height: 500 }} />
  }

  _onButton = async (signupForm: GetSignupFormRes, status: GetSignupFormRes['status']) => {
    let title = ''
    let content = ''

    if (status === 'DRAFT') {
      title = '解鎖'
      content = '確定要解鎖, 讓申請人可繼續編輯此表格?'
    } else if (status === 'APPROVED') {
      title = '審核'
      content = '確定要通過此申請人的審核?'
    } else if (status === 'ACCEPTED') {
      title = '接納'
      content = '確定要接納此申請?'
    } else if (status === 'REFERRED') {
      title = '轉介'
      content = '確定要轉介此申請?'
    }

    Modal.confirm({
      title,
      content,
      onOk: async () => {
        try {
          this.setState({
            spinning: true
          })

          switch (status) {
            case 'DRAFT': {
              await this._putSignupForm({ status: 'DRAFT', id: this.props.signupFormId })
            }
              break
            case 'APPROVED': {
              await this._putSignupForm({ status: 'APPROVED', id: this.props.signupFormId })
            }
              break
            case 'ACCEPTED': {
              await this._putSignupForm({ status: 'ACCEPTED', id: this.props.signupFormId })
            }
              break
            case 'REFERRED': {
              await this._postSignupFormReferred({ id: this.props.signupFormId })
            }
              break
          }

          this.setState({
            spinning: false,
          })
          this.props.onOk(status)

        } catch (error) {
          this.setState({
            spinning: false
          })
          console.log(error)
        }
      },
    });
  }

  _onButtonResend = async (signupForm: GetSignupFormRes) => {
    Modal.confirm({
      title: '解鎖並重發短訊',
      content: '確定要解鎖並重發短訊, 讓申請人可繼續編輯此表格?',
      onOk: async () => {
        try {
          this.setState({
            spinning: true
          })

          await this._postSignupFormResendExpiredSms({ id: this.props.signupFormId })

          this.setState({
            spinning: false,
          })
          this.props.onOk('RESEND')

        } catch (error) {
          this.setState({
            spinning: false
          })
          console.log(error)
        }
      },
    });
  }

  _onButtonNotApproved = async () => {
    this.setState({
      editNotApprovedModal: {
        key: new Date().toString(),
        visible: true,
      },
    })
  }

  _onButtonHistory = async () => {
    this.setState({
      editHistoryModal: {
        key: new Date().toString(),
        visible: true,
      },
    })
  }

  _footerView = () => {
    const signupForm = this.state.signupForm
    if (signupForm) {
      return (
        <>

          {!['ACCEPTED', 'REFERRED',].some(status => status === signupForm.status) && (
            <Button
              type="ghost"
              size="large"
              onClick={() => this._onButton(signupForm, 'REFERRED')}
              icon={<CloseOutlined />}
            >
              轉介
            </Button>
          )}

          {['APPROVED', 'SUBMITTED', 'EXPIRED'].indexOf(signupForm.status) > -1 && (
            <Button
              type='ghost'
              size="large"
              onClick={() => this._onButton(signupForm, 'DRAFT')}
              icon={<UnlockOutlined />}
            >
              解鎖
            </Button>
          )}

          {['EXPIRED'].indexOf(signupForm.status) > -1 && (
            <Button
              danger
              type='ghost'
              size="large"
              onClick={() => this._onButtonResend(signupForm)}
              icon={<UnlockOutlined />}
            >
              解鎖並重發短訊
            </Button>
          )}

          {['DRAFT', 'APPROVED', 'SUBMITTED', 'EXPIRED'].indexOf(signupForm.status) > -1 && (
            <Button
              danger
              type="primary"
              size="large"
              onClick={() => this._onButtonNotApproved()}
              icon={<CloseOutlined />}
            >
              拒絕
            </Button>
          )}

          {signupForm.status === 'SUBMITTED' && (
            <Button
              type="primary"
              size="large"
              onClick={() => this._onButton(signupForm, 'APPROVED')}
              icon={<CheckOutlined />}
            >
              通過審核
            </Button>
          )}

          {signupForm.status === 'APPROVED' && (
            <Button
              type="primary"
              size="large"
              onClick={() => this._onButton(signupForm, 'ACCEPTED')}
              icon={<CheckOutlined />}
            >
              通過接納
            </Button>
          )}


        </>
      )
    }
    return <div />
  }


  render() {
    return (
      <>
        <Modal
          className="signup-form-detail-description-container"
          maskClosable={false}
          title={this.props.title}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          width={'90%'}
          footer={this._footerView()}
        >
          <div id="FoodCardDetailsModal"
          >
            <Spin
              spinning={this.state.spinning}
            >
              {this._view(this.state.signupForm)}
            </Spin>
          </div>
        </Modal>

        <SignupFormDetailsModalImageBrowserModal
          key={this.state.imageBrowserModal.key}
          visible={this.state.imageBrowserModal.visible}
          id={this.props.signupFormId}
          index={this.state.imageBrowserModal.index}
          images={this.state.images}
          onChange={(index) => {
            this.setState({
              imageBrowserModal: {
                ...this.state.imageBrowserModal,
                index,
              },
            })
          }}
          onUpdate={(indexChanged) => {
            this.setState({
              images: this.state.images.map((image, index) => {
                if (index !== indexChanged) {
                  return image
                } else {
                  const url = image.url.replace(/\?.$/, '') + '?t=' + Date.now();
                  return {
                    ...image,
                    url,
                    thumbnailUrl: url + '&thumbnail=true',
                  };
                }
              }),
            })
          }}
          onCancel={() => {
            this.setState({
              imageBrowserModal: {
                ...this.state.imageBrowserModal,
                visible: false,
              },
            })
          }}
        />

        {this.state.editFamilyMemberModal.id && (
          <SignupFormDetailsModalFamilyMemberModal
            key={this.state.editFamilyMemberModal.key}
            show={this.state.editFamilyMemberModal.visible}
            id={this.state.editFamilyMemberModal.id}
            onOk={() => {
              this.setState({
                editFamilyMemberModal: {
                  ...this.state.editFamilyMemberModal,
                  visible: false,
                },
              });
              this._onLoadData();
            }}
            onCancel={() => {
              this.setState({
                editFamilyMemberModal: {
                  ...this.state.editFamilyMemberModal,
                  visible: false,
                },
              })
            }}
          />
        )}

        <SignupFormDetailsModalNotApprovedModal
          key={this.state.editNotApprovedModal.key}
          show={this.state.editNotApprovedModal.visible}
          id={this.props.signupFormId}
          onOk={() => {
            this.setState({
              editNotApprovedModal: {
                ...this.state.editNotApprovedModal,
                visible: false,
              },
            });
            this._onLoadData();
          }}
          onCancel={() => {
            this.setState({
              editNotApprovedModal: {
                ...this.state.editNotApprovedModal,
                visible: false,
              },
            })
          }}
        />

        {this.state.editHistoryModal.key && (
          <SignupFormDetailsModalHistoryModal
            key={this.state.editHistoryModal.key}
            show={this.state.editHistoryModal.visible}
            id={this.props.signupFormId}
            onCancel={() => {
              this.setState({
                editHistoryModal: {
                  ...this.state.editHistoryModal,
                  visible: false,
                },
              })
            }}
          />
        )}
      </>
    )
  }

}
export default connector(SignupFormDetailsModal)


