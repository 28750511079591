import React, { } from 'react';
import JJ_From, { FormItemData } from "../../components/JJ_From";
import api, { GetProductParameters, PutProductParameters, PostProductParameters, GetProductListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import JJ_FromI18nInputText from '../../components/JJ_FromI18nInputText';
import { Input } from 'antd';

interface PageState {

}


export type ProductEditAndAddPropsType = { type: 'add' } | { type: 'edit', id: string }

interface Props {
  onFinish?(props: PageProps, id: string): void
}



interface Page {
  /**
   * 保存数据
   */
  _onFinish(values: any): void

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

  id?: string

}


type PageProps = Props & ProductEditAndAddPropsType & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = (state: RootState) => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class ProductEditAndAdd extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {

    }

  }
  componentDidMount() {
  }

  _onFinish = async (values: any): Promise<void> => {

    console.log('_onFinish', this.props.type, values)

    switch (this.props.type) {
      case 'edit': {
        const res = await this._putProduct({
          ...values,
          id: this.props.id,
          maxCount: values.maxCount || undefined,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
      case 'add': {
        const res = await this._postProduct({
          ...values,
          maxCount: values.maxCount || undefined,
        })
        this.props.onFinish && this.props.onFinish(this.props, res.id)
      }
        break
    }
  }

  _onLoadData = async (): Promise<any> => {
    switch (this.props.type) {
      case 'edit': {
        const res = await this._getProduct({ id: this.props.id })
        return {
          ...res,
        }
      }
      case 'add': {

        return {

        }
      }


    }
  };

  _getProduct = async (param: GetProductParameters) => {
    const res = await api.GetProduct(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _getProductList = async (param: GetProductListParameters) => {
    const res = await api.GetProductList(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }

  _putProduct = async (param: PutProductParameters) => {
    const res = await api.PutProduct(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }
  _postProduct = async (param: PostProductParameters) => {
    const res = await api.PostProduct(param)
    if (res.kind !== 'ok') {
      throw new Error(res.kind)
    }
    return res.data
  }


  _formListData = (): FormItemData[] => {
    switch (this.props.type) {
      case 'edit': {
        return [
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
                message: `請輸入標題`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },
          {
            id: 'maxCount',
            label: '最大貨道容量',
            rules: [
              {
                required: false,
                message: `請輸入最大貨道容量`,
              }
            ],
            componet: (<Input type='number' />)

          },
          {
            id: 'submit',
          },
        ]
      }
      case 'add': {

        return [
          {
            id: 'i18nText',
            name: 'title',
            label: '標題',
            rules: [
              {
                required: true,
                message: `請輸入標題`,
              }
            ],
            componet: (<JJ_FromI18nInputText />)
          },
          {
            id: 'uniqueCode',
            label: '唯一碼',
            rules: [
              {
                required: true,
                message: `請輸入唯一碼`,
              }
            ],
          },
          {
            id: 'maxCount',
            label: '最大貨道容量',
            rules: [
              {
                required: false,
                message: `請輸入最大貨道容量`,
              }
            ],
            componet: (<Input type='number' />)

          },
          {
            id: 'submit',
          },
        ]
      }


    }
  }

  render() {
    return (
      <div id="ProductEditAndAdd">
        <JJ_From
          formListData={this._formListData()}
          onLoadData={this._onLoadData}
          onFinish={this._onFinish}
        />
      </div>
    )
  }

}
export default connector(ProductEditAndAdd)
