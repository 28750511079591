import React, { } from 'react';
import api, { PostFoodCardDownloadImageParameters, GetFoodCardRes, GetFoodCardListParameters } from '../../api';
import { connect, ConnectedProps } from 'react-redux';
import { Spin, Alert, Tree, Modal, message, Button, Layout, Carousel, Divider, Space, Typography, Tag } from 'antd';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import FoodCardDetailsRecordList from './FoodCardDetailsRecordList';


interface PageState {
  foodCards: GetFoodCardRes[]
  selectPage: number
  spinning?: boolean
  downloading?: boolean
}

interface Props {
  title: string
  visible: boolean
  onCancel(): void
  foodCardCode: string
}

interface Page {

  /**
   * 获取数据
   */
  _onLoadData(): Promise<{}>

}


type PageProps = Props & PropsFromRedux

type PropsFromRedux = ConnectedProps<typeof connector>


interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)


class FoodCardDetailsModal extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);

    this.state = {
      foodCards: [],
      selectPage: 0,
    }

  }
  componentDidMount() {
    this._onLoadData()
  }


  _getFoodCardList = async (params: GetFoodCardListParameters) => {
    const res = await api.GetFoodCardList(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }


  _postFoodCardDownloadImage = async (params: PostFoodCardDownloadImageParameters) => {
    const res = await api.PostFoodCardDownloadImage(params)
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data
  }

  _onLoadData = async (): Promise<any> => {

    try {
      this.setState({
        spinning: true
      })
      const foodCards = await this._getFoodCardList({ isAscend: false, showFoodCardStatusData: true, searchText: this.props.foodCardCode.slice(0, this.props.foodCardCode.length - 3) })
      this.setState({
        spinning: false,
        foodCards: foodCards.data,
      }, () => {
        setTimeout(() => {
          const selectPage = this.state.foodCards.findIndex(foodCard => foodCard.code === this.props.foodCardCode)
          this.refCarousel && this.refCarousel.goTo(selectPage)
          this.setState({
            selectPage,
          })
        }, 1500);
      })
    } catch (error) {
      this.setState({
        spinning: false
      })
      console.log(error)
    }
  };

  refCarousel?: Carousel | null
  isRefCarousel?: undefined | boolean
  _view = () => {

    return (
      <Layout
        style={{
          backgroundColor: 'white',
        }}
      >
        <Carousel
          ref={ref => {
            this.refCarousel = ref
          }}
          effect="fade"
          dots={false}
          dotPosition={'bottom'}
          style={{
            backgroundColor: 'white',
          }}
          afterChange={e => {
            console.log('afterChange', e)
            this.setState({
              selectPage: e
            })
          }}
          beforeChange={e => {
            console.log('beforeChange', e)
          }}
        >
          {this.state.foodCards.map((foodCard, index) => {
            return (
              <div
                key={index}
              >
                <div>
                  <h2>{foodCard.code}</h2>
                </div>

                <Space
                  size={30}
                >
                  <h3>本日餘額: {foodCard.foodCardStatusData.dayFoodCount}</h3>
                  <h3>本月餘額: {foodCard.foodCardStatusData.monthFoodCount}</h3>
                  <h3>狀態：{foodCard.foodCardStatusData.isAvailable ? <Tag color="green">可用</Tag> : <Tag color="red">停用</Tag>}</h3>
                  <Button
                    type="primary"
                    // shape="circle"
                    size='small'
                    icon={<DownloadOutlined />}
                    loading={this.state.downloading}
                    onClick={async () => {
                      try {
                        this.setState({
                          downloading: true
                        })

                        const res = await this._postFoodCardDownloadImage({ foodCardCodes: [this.state.foodCards[this.state.selectPage].code] })
                        window.open(res.data)

                        this.setState({
                          downloading: false
                        })

                      } catch (error) {
                        console.log(error)

                        this.setState({
                          downloading: false
                        })
                      }

                    }}
                  >
                    下載圖片
                  </Button>
                </Space>
                <FoodCardDetailsRecordList
                  foodCard={foodCard}
                />
              </div>
            )
          })}
        </Carousel>
        <Space
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10,
          }}
          size={30}
        >
          <Button
            type="primary"
            // shape="circle"
            size='large'
            disabled={this.state.selectPage === 0}
            icon={<CaretLeftOutlined />}
            onClick={() => this.refCarousel && this.refCarousel.prev()}
          />
          <Button
            type="primary"
            // shape="circle"
            size='large'
            disabled={this.state.selectPage === this.state.foodCards.length - 1}
            icon={<CaretRightOutlined />}
            onClick={() => this.refCarousel && this.refCarousel.next()}
          />
        </Space>

      </Layout>
    )
  }


  render() {
    return (

      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        width={'90%'}
        footer={null}
      >
        <div id="FoodCardDetailsModal"
        >
          <Spin
            spinning={this.state.spinning}
          >
            {this._view()}
          </Spin>
        </div>
      </Modal>
    )
  }

}
export default connector(FoodCardDetailsModal)


