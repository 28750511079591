import React, { } from 'react';
import { RouteChildrenProps, } from 'react-router-dom';
import { Modal, message, Spin, Button, Tag, Space } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api, { GetFoodCardGroupListParameters, GetFoodCardGroupRes } from '../../api';
import JJ_Table from '../../components/JJ_Table';
import JJ_TableItemAction from '../../components/JJ_TableItemAction';
import JJ_TableItemMoment from '../../components/JJ_TableItemMoment';
import JJ_TableItemCopyableText from '../../components/JJ_TableItemCopyableText';
import { PlusOutlined } from '@ant-design/icons';
import { connect, ConnectedProps } from 'react-redux';
import FoodCardGroupEditAndAdd from './FoodCardGroupEditAndAdd';
import JJ_TableItemCopyableId from '../../components/JJ_TableItemCopyableId';
import config from '../../config';

const { confirm } = Modal;



type EditAndAddModalType = { type: 'edit', id: string } | { type: 'addXlsx' } | { type: 'add' }

interface PageState {
  isSpinLoading: boolean
  editAndAddModal: {
    show: boolean
    key: string
  } & EditAndAddModalType
}
interface Props {
  foodCardUserMemberFamilyId?: string
}


interface Page {
  tableRef?: JJ_Table<GetFoodCardGroupRes> | null
}


type PageProps = Props  & ConnectedProps<typeof connector>

interface RootState {

}

const mapState = () => ({

})

const mapDispatch = {

}

const connector = connect(
  mapState,
  mapDispatch
)

class FoodCardGroupList extends React.Component<PageProps, PageState> implements Page {

  constructor(props: Readonly<PageProps>) {
    super(props);
    this.state = {
      isSpinLoading: true,
      editAndAddModal: {
        type: 'addXlsx',
        show: false,
        key: new Date().toString()
      }
    }
  }
  tableRef?: JJ_Table<GetFoodCardGroupRes> | null
  componentDidMount() {
    this._initData()
  }
  _initData = async () => {
    try {
      this.setState({
        isSpinLoading: true
      })
      await Promise.resolve()
      this.setState({
        isSpinLoading: false
      })
    } catch (error) {
      this.setState({
        isSpinLoading: false
      })
    }
  }
  render() {
    return (
      <div id="FoodCardGroupList"
      >
        <Space align='center' >
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'add' }
            }))}
            icon={<PlusOutlined />}
          >
            {`添加八達通`}
          </Button>
          <Button
            type='primary'
            style={{ marginBottom: 5 }}
            onClick={() => this.setState(state => ({
              editAndAddModal: { ...state.editAndAddModal, show: true, key: new Date().toString(), type: 'addXlsx' }
            }))}
            icon={<PlusOutlined />}
          >
            {`導入Excel`}
          </Button>
        </Space>

        <Modal
          maskClosable={false}
          title={(() => {
            switch (this.state.editAndAddModal.type) {

              case 'addXlsx': {
                return '批量編輯八達通'
              }
              case 'edit': {
                return '編輯八達通'
              }
              case 'add': {
                return '添加八達通'
              }
            }
          })()}
          visible={this.state.editAndAddModal.show}
          footer={null}
          onCancel={() => this.setState(state => ({
            editAndAddModal: { ...state.editAndAddModal, show: false }
          }))}
          width={'85%'}
        >
          <FoodCardGroupEditAndAdd
            {...this.state.editAndAddModal}
            onFinish={async (props) => {
              this.setState(state => ({
                editAndAddModal: { ...state.editAndAddModal, show: false }
              }))
              switch (props.type) {
                case 'addXlsx': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'add': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`添加成功`)
                }
                  break
                case 'edit': {
                  this.tableRef && await this.tableRef.refreshData()
                  message.success(`编辑成功`)
                }
              }

            }}
          />
        </Modal>

        <Spin spinning={this.state.isSpinLoading}>
          {!this.state.isSpinLoading && (
            <JJ_Table<GetFoodCardGroupRes, {
              desc: 'ascend' | 'descend'
              isEnable: 'true' | 'false'
              type?: GetFoodCardGroupListParameters['type']
            }>
              ref={ref => this.tableRef = ref}
              isSearchText={true}
              sourceItems={[
                {
                  type: 'select',
                  defaultValue: 'ascend',
                  key: 'desc',
                  options: [
                    {
                      value: 'ascend',
                      name: '升序',
                      disabled: false,
                    },
                    {
                      value: 'descend',
                      name: '降序',
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意類型',
                  key: 'type',
                  allowClear: true,
                  options: [
                    {
                      value: 'OCTOPUS',
                      name: <Tag color="blue">OCTOPUS</Tag>,
                      disabled: false,
                    },
                  ]
                },
                {
                  type: 'select',
                  defaultValue: undefined,
                  placeholder: '請選擇任意狀態',
                  key: 'isEnable',
                  allowClear: true,
                  options: [
                    {
                      value: 'false',
                      name: <Tag color="red">停用</Tag>,
                      disabled: false,
                    },
                    {
                      value: 'true',
                      name: <Tag color="blue">啟用</Tag>,
                      disabled: false,
                    },
                  ]
                },

              ]}
              columns={[
                {
                  key: 'action',
                  width: 50,
                  render: (value: any, record) => {
                    return (
                      <JJ_TableItemAction
                        menus={[
                          {
                            type: 'item',
                            disabled: false,
                            key: 'edit',
                            name: '編輯',
                            onAction: () => {
                              this.setState(state => ({
                                editAndAddModal: { ...state.editAndAddModal, show: true, type: 'edit', id: record.id, key: new Date().toString() }
                              }))
                            }
                          },
                          {
                            type: 'item',
                            disabled: config.REACT_APP_DELETE_DISABLED,
                            key: 'delete',
                            name: '删除',
                            onAction: () => {
                              confirm({
                                title: `是否删除${record.foodCardUserMemberFamily?.foodCardCode}`,
                                icon: <ExclamationCircleOutlined />,
                                okText: 'Yes',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk: async () => {
                                  try {
                                    await api.DeleteFoodCardGroup({ id: record.id })
                                    this.tableRef && this.tableRef.refreshData()
                                    message.success(`${record.foodCardUserMemberFamily?.foodCardCode} 删除成功`)
                                  } catch (error) {
                                    message.error(`${record.foodCardUserMemberFamily?.foodCardCode} 删除失败 [${error.message}]`);
                                  }
                                },
                                onCancel() {
                                },
                              });
                            }
                          },
                        ]}
                      />
                    )

                  },
                },
                {
                  title: 'ID',
                  dataIndex: 'id',
                  key: 'id',
                  width: 50,
                  render: (value) => (
                    <JJ_TableItemCopyableId
                      text={value}
                    />
                  )
                },
                {
                  title: '八達通號',
                  dataIndex: 'externalId',
                  key: 'externalId',
                  render: (value) => (
                    <JJ_TableItemCopyableText
                      length={100}
                      text={value}
                    />
                  )
                },
                {
                  title: '電話號碼',
                  dataIndex: 'phone',
                  key: 'phone',
                  width: 200,
                  render: (value, record) => {
                    const [areaCode, phoneCode] = String(record.foodCardUserMemberFamily.phone).split('-')
                    if (phoneCode) {
                      return phoneCode
                    }

                  }
                },

                {
                  title: '食品卡代號',
                  dataIndex: ['foodCardUserMemberFamily', 'foodCardCode'],
                  key: 'foodCardUserMemberFamily.foodCardCode',
                },

                {
                  title: '是否啟用',
                  dataIndex: 'isEnable',
                  key: 'isEnable',
                  render: (value, record) => (
                    record.isEnable
                      ? <Tag color="blue">啟用</Tag>
                      : <Tag color="red">停用</Tag>
                  )
                },
                {
                  title: '创建时间',
                  dataIndex: 'createdAt',
                  key: 'createdAt',
                  render: (value) => (
                    <JJ_TableItemMoment
                      date={value}
                    />
                  )
                },
              ]}
              title={'食品卡清單'}
              onDataSource={async (body) => {
                const { sourceItemBody } = body
                const params: {
                  isAscend?: GetFoodCardGroupListParameters['isAscend']
                  isEnable?: GetFoodCardGroupListParameters['isEnable']
                  type?: GetFoodCardGroupListParameters['type']
                } = {}
                if (sourceItemBody) {
                  if (sourceItemBody.desc) {
                    params.isAscend = sourceItemBody.desc === 'ascend' ? true : false
                  }
                  if (sourceItemBody.isEnable !== undefined) {
                    params.isEnable = sourceItemBody.isEnable === 'true'
                  }
                  params.type = sourceItemBody.type
                }
                const res = await api.GetFoodCardGroupList({
                  ...body,
                  ...params,
                  scope: ['foodCardUserMemberFamily'],
                  foodCardUserMemberFamilyId: this.props.foodCardUserMemberFamilyId,
                })
                if (res.kind !== 'ok') throw new Error(res.kind)
                return res.data
              }}
            />
          )}
        </Spin>

      </div>
    )
  }

}
export default connector(FoodCardGroupList)



